@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aoboshi+One&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  background-color: #000000e4;
  padding: 1.2rem;
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: var(--radius);
}

:root {
  --body-color: #f4f6f6;
  --bg-color: #064663;
  --hover-color: #1f6382;
  --radius: 4px;
  --primery-color: #f7f7f7;
  --paragraph-color: #dddddd;
}

.dropdown-item,
button,
a {
  font-size: 11px !important;
  text-decoration: none;
  color: rgb(114, 171, 255);
  padding: 6px 10px;
}

p {
  font-size: 12px;
  margin-bottom: 8px;
}
h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
h4 {
  margin-bottom: 8px;
  font-size: 18px;
}

ul {
  padding-left: 0;
}
ul li {
  list-style: none;
}

.dropdown-toggle::after {
  display: none;
}
.grid-img img,
.main_category li img,
.category img,
.succe-img img,
.coupons-logo img,
.client-logo-image img,
.card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media_image img,
.main-input img,
.view-image img,
.product-image img,
.header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.all-product,
.main_category li button,
.card-img span,
.overflow_style,
.store-grid,
.coupons-grid,
.overflow_style span,
.card-img .dropdown-menu.show,
.card-img .dropdown-item,
.btn-group button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tags-result ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.all-product,
.store-grid {
  flex-direction: row;
  flex-wrap: wrap;
}

.grid-content a:hover,
.header-image span a:hover {
  text-decoration: underline;
}

.card-grid {
  margin: 2rem 0;
}

.container-fluid,
.container {
  padding: 0 !important;
}

.global_form_button,
.btn-close,
.signup-btn button,
.updated-product a,
.main-input button,
.product-header button,
.published-btn button,
.product-header a,
.input-fields button,
.preview-tag a,
.preview-tag button {
  border: 1px solid var(--hover-color);
  background-color: #1f6382a5;
  color: var(--primery-color);
  border-radius: var(--radius);
}

.global_form_button:hover,
.signup-btn button:hover,
.btn-close:hover,
.input-fields button:hover,
.product-header button:hover,
.updated-product a:hover,
.main-input button:hover,
.published-btn button:hover,
.product-header a:hover {
  background-color: var(--hover-color);
  color: var(--primery-color);
}

.coupons-grid {
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.view-image img,
.product-image img {
  border-radius: var(--radius);
}
.btn-group-profile {
  font-size: 20px !important;
}
/* --------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------- */
.succe-img {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.lead {
  color: var(--primery-color);
}

.navbar-brand {
  color: var(--primery-color) !important;
}
.navbar-brand img {
  width: 150px;
  height: 40px;
  object-fit: contain;
  background-color: #fff;
  padding: 2px;
  border-radius: var(--radius);
}
.nav-item {
  margin: 0 2px;
}

.nav-item .nav-link {
  border-radius: var(--radius);
  color: var(--primery-color) !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.nav-item .nav-link:focus {
  background-color: var(--body-color);
  color: var(--hover-color) !important;
}
.dropdown-item span,
.nav-item .nav-link span {
  font-size: 12px !important;
  margin-right: 4px;
}
.btn-group {
  margin-right: 14px;
}
.btn-group button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--body-color) !important;
  color: var(--font-color) !important;
}
.btn-group button:active,
.btn-group button:focus {
  box-shadow: none !important;
}

.dropdown-menu.show,
.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: #0000005a;
  backdrop-filter: blur(9px);
  margin-top: 12px;
  padding: 10px;
}
.dropdown-item {
  border-radius: var(--radius);
  font-size: 12px !important;
  color: var(--primery-color);
  font-weight: lighter !important;
  margin-bottom: 10px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--hover-color);
}

.logout-btn:hover {
  background-color: rgb(153, 5, 5) !important;
  color: var(--primery-color);
}

/* ----------------------------blog-seation-start----------------------------------- */
.card-grid {
  height: auto;
}
.card-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.blog-grid {
  width: 280px;
  height: auto;
  border-radius: var(--radius);
  text-align: center;
  padding: 10px;
  background-color: var(--body-color);
  backdrop-filter: blur(4px);
  margin: 10px;
}
.blog-info {
  padding: 4px 0;
}
.blog-grid h4 {
  font-size: 16px;
}
.blog-grid h5 {
  font-size: 13px;
}
.blog-info h6 {
  background-color: var(--bg-color);
  color: var(--primery-color);
  margin-bottom: 0px;
  font-size: 12px;
  border-radius: var(--radius);
  padding: 2px 10px;
}
.approve {
  margin-bottom: 0;
  font-size: 11px;
  background-color: rgba(2, 125, 2, 0.559) !important;
  border: 1px solid rgb(0, 84, 0) !important;
}

.failed {
  background-color: rgba(125, 2, 2, 0.559) !important;
  border: 1px solid rgb(84, 0, 0) !important;
}

.grid-img {
  width: 100%;
  height: 20vh;
  margin-bottom: 10px;
  border-radius: var(--radius);
  position: relative;
}
.blog-crud {
  height: auto;
  position: absolute !important;
  top: 10px !important;
  right: -4px !important;
}
.grid-img img {
  border-radius: var(--radius);
}
.blog-crud button {
  width: 30px !important;
  height: 30px !important;
}
.blog-crud button span {
  font-size: 1.2rem !important;
}
.blog-crud .dropdown-menu li a {
  margin-bottom: 0px !important;
}
.blog-grid .dropdown-menu.show {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dropdown-menu li button {
  width: 100%;
  border-radius: var(--radius) !important;
  padding: 6px !important;
  height: auto !important;
}

/* --------------------------------header-section-start----------------------------------------- */

.header-image {
  position: relative;
  width: 100%;
  height: 50vh;
}
.header-image img {
  border-radius: var(--radius);
}
.header-image span {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
}
.header-image span h2 {
  color: var(--primery-color);
  font-weight: bold;
}
.header-image span a {
  font-size: 15px;
  color: var(--primery-color);
}

.header-grid-content {
  padding: 4rem 0;
}

.header-icon span svg {
  font-size: 2rem;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: var(--radius);
  background-color: var(--bg-color);
  color: var(--primery-color);
  padding: 4px;
}

/* -------------------------------notify-section-start-------------------------------- */
.notify-section {
  border: 1px solid red;
  height: 50vh;
  border-radius: var(--radius);
}

/* ----------------------------------product-seaction-start------------------------------ */

.product-header h4 {
  color: var(--primery-color);
  margin-bottom: 0;
  margin-right: 1rem;
}

.product-header button:nth-child(3) {
  margin: 0 10px;
}
.publisher-all h5 {
  width: auto;
  color: var(--primery-color);
  margin-bottom: 0;
  margin-right: 14px;
}

.publisher-all span {
  color: var(--primery-color);
}

.product-search {
  width: 22%;
}
.input-details input,
.product-search form input {
  border-radius: var(--radius);
  font-size: 13px;
  padding: 10px;
  width: 100%;
  border: none;
}
.input-details input:focus,
.product-search form input:focus {
  outline: none;
}
td,
th {
  color: var(--primery-color);
  font-size: 13px;
  font-weight: lighter;
}

tbody {
  border: 1px solid var(--primery-color);
}
tr td img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
tr td:nth-child(3) {
  width: 13%;
}
tr td:nth-child(4) {
  width: 15%;
}
tr td:nth-child(5) {
  width: 5%;
}
tr td:nth-child(8) {
  width: 17%;
}

/* -----------------------------add-product-section-start------------------------------ */
.add-product {
  background-color: var(--body-color);
  height: auto;
  border-radius: var(--radius);
}
.add-header h5 {
  color: var(--primery-color);
  font-size: 16px;
  border-radius: var(--radius);
  padding: 10px;
  background-color: var(--bg-color);
}

.publish-list ul li {
  color: var(--font-color);
  font-size: 14px;
}
.edit-icon {
  font-size: 20px;
}
.edit-icon:nth-child(1) {
  color: var(--font-color);
}
.edit-icon:nth-child(3) {
  margin-left: 16px;
}

/* ---------------------------grid-section---------------------------------- */

.grid-section {
  width: 200px;
  background-color: var(--body-color);
  border-radius: var(--radius);
  margin: 10px;
}
.grid-section .card-img {
  width: 100%;
  height: 14vh;
  padding: 1rem;
  position: relative;
}
.store-content {
  padding: 2px;
}
.store-content span {
  padding: 2px 10px;
  font-size: 12px;
  border-radius: var(--radius);
  color: var(--primery-color);
  border: 1px solid rgb(6, 86, 6);
  background-color: rgba(0, 79, 0, 0.549);
}
.store-content .not-active {
  border: 1px solid rgb(86, 6, 6);
  background-color: rgba(79, 0, 0, 0.549);
}
.card-img .dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
}
.card-img .dropdown span {
  border: 1px solid #fff;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  color: var(--bg-color);
  display: none;
}

.card-img:hover .dropdown span {
  display: block;
}

.card-img .dropdown-item {
  border: 1px solid #fff;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  margin: 0 2px;
}
.card-img .dropdown-menu {
  min-width: 8rem !important;
  backdrop-filter: blur(5px);
  background-color: #000000a3;
}
.store-content h4 {
  font-size: 14px;
  margin-bottom: 1rem;
  background-color: var(--bg-color);
  color: var(--primery-color);
  padding: 2px;
}
.btn-close:focus {
  box-shadow: none;
}

/* ----------------------product-img-------------------------- */
.tox .tox-editor-container {
  width: 55.5rem !important;
}
.product-grid {
  width: 230px;
  height: auto;
  background-color: var(--primery-color);
  border-radius: var(--radius);
  margin: 10px;
}
.product-img {
  position: relative;
  width: 100%;
  height: 26vh;
}
.product-content {
  margin: 5px;
}
.product-content h4 {
  font-size: 12px;
}
.product-content p {
  font-size: 12px;
}
.client-Product {
  background-color: var(--bg-color) !important;
}
.product-content h6 {
  font-size: 13px;
}

.form-view-contact {
  width: 100%;
  margin: 0 auto;
  height: auto;
  backdrop-filter: blur(5px);
  border-radius: var(--radius);
}
.contact-heading {
  margin-bottom: 3rem;
}
.contact-heading p {
  text-align: center;
  font-size: 14px;
  color: gray;
  width: 75%;
  margin: 0 auto;
}

.form-view {
  background-color: var(--body-color);
  border-radius: var(--radius);
  backdrop-filter: blur(4px);
}
.form-view .form-d {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
}
.input-fields {
  flex-basis: 48%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 14px;
}
.input-title {
  flex-basis: 100%;
}
.main-input {
  flex-basis: 100% !important;
}
.input-fields select,
.input-fields textarea,
.input-fields input {
  width: 100%;
  border: none;
  padding: 12px;
  font-size: 13px;
  border-radius: var(--radius);
}
.input-fields label {
  font-size: 14px;
  margin-bottom: 5px;
}
.input-fields select:focus,
.input-fields textarea:focus,
.input-fields input:focus {
  outline: none;
}
.optionl-point span {
  font-size: 10px;
  margin-left: 4px;
}
.product-image {
  width: 100%;
  height: 36vh;
}

.table-sizes {
  width: 48%;
}

/* --------------------------------single-product-section-------------------------------- */
.view-image {
  width: 100%;
  height: 70vh;
  position: relative;
}
.updated-product {
  position: absolute;
  top: 10px;
  right: 10px;
}
.product-content-review {
  border-radius: var(--radius);
  padding: 1rem;
  background-color: var(--body-color);
  height: 70vh;
}
.product-content-review .product-content {
  width: 90%;
  margin: 0 auto;
}
.product-he {
  height: auto;
}

/* ----------------------------------coupons-section-start------------------------------------------- */
.coupons-grid {
  margin-top: 1rem;
}
.coupons-card {
  width: 290px;
  height: auto;
  padding: 10px;
  border-radius: var(--radius);
  background-color: var(--primery-color);
  text-align: center;
  margin: 10px;
  position: relative;
}
.coupons-logo {
  width: 60%;
  height: 8vh;
  margin: 0 auto;
  margin-bottom: 12px;
}
.coupons-logo img {
  padding: 6px;
  border-radius: var(--radius);
  background-color: #fff;
}
.coupons-title h3 {
  font-size: 18px;
}

.coupons-title p {
  font-size: 12px;
}
.coupons-detail a {
  background-color: var(--body-color);
  color: var(--font-color);
}
.view-coupons h5 {
  margin-bottom: 10px;
  font-size: 15px;
  letter-spacing: 1px;
}

.coupons-detail a:hover {
  background-color: var(--hover-color);
  color: var(--body-color);
}
.coupons-validaty {
  margin-bottom: 10px;
  background-color: var(--body-color);
  border-radius: 3px;
}
.coupons-validaty h5 {
  font-size: 12px;
  padding: 2px;
  color: var(--font-color);
}

/* -----------------------modal-section-start---------------------------------- */
.modal-dialog {
  max-width: 600px !important;
}
.client-logo-image {
  width: 30%;
  height: 8vh;
}
.btn-close {
  border-radius: 50%;
}
.main-input {
  height: auto;
}
.main-input img {
  border-radius: var(--radius);
}

/* -------------------------blogs----------------------------- */
.tags-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.tags-btn input {
  width: 75%;
  padding: 8px !important;
}

.tags-btn button {
  padding: 8px 20px;
}

.tags-result ul li {
  font-size: 12px;
  padding: 4px 10px;
  margin-right: 10px;
  border-radius: var(--radius);
  background-color: var(--bg-color);
  color: var(--primery-color);
  margin-bottom: 10px;
}
.tags-result ul li span svg {
  font-size: 20px;
  margin-top: 0;
  color: rgb(255, 73, 73);
  cursor: pointer;
}
.tags-result ul li img {
  width: 70px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}
.trending-tags {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgb(255, 73, 73);
  font-size: 12px;
  padding: 2px 10px;
  border-radius: var(--radius);
  color: var(--primery-color);
}

/* ----------------------------comment-blog----------------------------- */
.comment-section {
  height: auto;
  position: relative;
}
.comment-bac {
  background-color: var(--body-color);
  border-radius: var(--radius);
}
.accordion-body h5 span {
  margin-right: 10px;
  width: 20%;
  height: 10vh;
  background-color: var(--hover-color);
  color: var(--primery-color);
  border-radius: var(--radius);
}

.accordion-body h5 p {
  width: 80%;
}

/* -------------------------------------editor-change------------------------------------------- */
/* .tox .tox-editor-container {
  width: 55rem !important;
} */

.loading-section {
  color: var(--primery-color);
  font-weight: lighter !important;
  text-align: center;
  font-size: 12px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tox .tox-statusbar__text-container {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

/* -------------------------login-section---------------------------------- */
.login-form-section {
  width: 100%;
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-section form {
  width: 30%;
  padding: 40px 16px;
  box-shadow: 0px 0px 25px -2px var(--primery-color);
  border-radius: var(--radius);
}
.input-fields img {
  width: 85%;
  margin-bottom: 12px !important;
  height: auto;
  margin: 0 auto;
}
.input-fields button {
  width: 100%;
  padding: 10px !important;
  font-size: 13px !important;
}
.input-fields label {
  font-size: 13px;
  margin-bottom: 10px;
}
.input-fields h2 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--body-color);
  margin-bottom: 1rem;
}
.checkbox-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.checkbox-section p {
  color: var(--body-color);
  margin-bottom: 0;
}
.checkbox-section input {
  width: 10%;
}

.main_category {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.main_category li {
  border-radius: var(--radius);
}
.main_category li img {
  width: auto;
  height: 40px;
}
.main_category li button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 0;
}

.media_image {
  width: 100%;
  height: 24vh;
  position: relative;
}
.media_image img {
  border-radius: var(--radius);
}

.overflow_style {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--radius);
  background-color: rgba(139, 139, 139, 0.43);
  visibility: hidden;
}
.overflow_style span {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--body-color);
  color: var(--bg-color);
  padding: 2px;
  margin: 4px;
}
.media_image:hover .overflow_style {
  visibility: visible;
}

.addToggleSection {
  width: 100%;
}

.addToggleSection .published-btn button {
  width: 100px;
}
.table thead tr th:nth-child(1) {
  width: 5% !important;
}
.table thead tr th:nth-child(3) {
  width: 25% !important;
}
.table thead tr th:nth-child(2) {
  width: 14% !important;
}
.table thead tr th:nth-child(5) {
  width: 10% !important;
}
